import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from '../../components/WithRouter'
import HeadTitle from '../../components/HeadTitle'
import styles from './MAIndexPage.module.scss'
import { Flex, Box } from 'grid-styled'
import classNames from 'classnames'

import NotFound from '../NotFound'
import Flexgrid from '../../components/Flexgrid'
import Loading from '../../components/Loading'
import Chart from '../../components/Chart'
import Outline from '../../components/Outline'
import Table from '../../components/Table'
import More from '../../components/More'
import ExternalLink from '../../components/ExternalLink'
import BackLink from '../../components/BackLink'
import Locale from '../../components/Locale'
import { Breadcrumbs } from '../../components/Breadcrumbs'

import {
  getQuote,
  getConstituents,
  getConstituentsDownload
} from '../../api/otc/indices/indices'
import { fetch, expand, template, sort } from '../../api/helper'

import getConfig from '../../config'

import {
  FaLongArrowAltDown,
  FaLongArrowAltUp,
  FaDownload
} from 'react-icons/fa'

const { REACT_APP_API_BASE_URI } = getConfig()

const constituentsColumns = [
  {
    name: 'ticker',
    header: 'Symbol',
    type: 'symbol',
    sortingEnabled: true
  },
  {
    name: 'companyName',
    header: 'Company Name',
    sortingEnabled: true
  },
  {
    name: 'countryName',
    header: 'Country',
    hideOnMobile: true,
    sortingEnabled: true
  }
]

const IndicesHeader = ({ quote }) => (
  <Flex wrap>
    <Flex column w={[1, 1 / 2]} className={styles.header}>
      <h2>{quote.symbol}</h2>
      <p className={styles.companyName}>{quote.description}</p>
      <div className={styles.subHeader}>
        <p>Index</p>
      </div>
    </Flex>
    <Flex column w={[1, 1 / 2]} className={styles.bid}>
      <Flex justify={['flex-start', 'flex-end']} className={classNames(styles.bidline, {
        [styles.positive]: quote.change > 0,
        [styles.negative]: quote.change < 0
      })}>
        <h2><Locale type='price'>{quote.lastSale}</Locale></h2>
        {quote.change > 0 && <FaLongArrowAltUp />}
        {quote.change < 0 && <FaLongArrowAltDown />}
        <Flex column className={styles.evo}>
          <p className={styles.evoNumber}>{quote.change !== undefined ? <Locale type='price'>{quote.change}</Locale> : ''}</p>
          <p className={styles.evoPercent}>{quote.change !== undefined ? <Locale type='percentage'>{quote.percentChange}</Locale> : ''}</p>
        </Flex>
      </Flex>
    </Flex>
  </Flex>
)

IndicesHeader.propTypes = {
  quote: PropTypes.object.isRequired
}

const layouts = {
  sm: [
    {
      type: 'flex',
      column: true,
      subtree: ['back', 'info', 'links']
    }
  ],
  md: [
    {
      type: 'flex',
      column: true,
      subtree: ['back', 'info']
    },
    {
      type: 'flex',
      subtree: [
        {
          type: 'flex',
          column: true,
          width: 1 / 2,
          className: styles.mdLeft,
          subtree: ['links']
        }
      ]
    }
  ],
  lg: [
    {
      type: 'flex',
      subtree: [
        {
          type: 'main',
          subtree: ['back']
        },
        {
          type: 'side',
          subtree: []
        }
      ]
    },
    {
      type: 'flex',
      subtree: [
        {
          type: 'main',
          subtree: ['info']
        },
        {
          type: 'side',
          subtree: ['links']
        }
      ]
    }
  ]
}

const initialState = {
  invalidSymbol: false,
  quote: {
    loaded: false,
    data: {},
    error: null
  },
  constituents: template('records')
}

class MAIndexPage extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = { ...initialState }
  }

  componentDidMount () {
    this.loadQuote(this.props.match.params.symbol)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const currentSymbol = this.props.match.params.symbol
    const nextSymbol = nextProps.match.params.symbol
    if (nextSymbol !== currentSymbol) {
      // reset state before loading
      this.setState({ ...initialState }, () => {
        this.loadQuote(nextSymbol)
      })
    }
  }

  loadQuote = (symbol) => {
    getQuote(symbol)
      .then(data => {
        this.setState({
          quote: {
            loaded: true,
            error: null,
            data
          }
        })
      })
      .catch((err) => {
        if (err.response && err.response.data.message.indexOf('not supported OTC symbol') > -1) {
          return this.setState({ invalidSymbol: true })
        }
      })

    // Only get constituents for OTC indices
    if (symbol.toLowerCase().indexOf('.otc') !== 0) return
    fetch(this, getConstituents, 'constituents', {
      symbol,
      page: 1,
      pageSize: 10
    })
  }

  render () {
    if (this.state.invalidSymbol) return <NotFound />
    const isOTC = this.props.match.params.symbol.toLowerCase().indexOf('.otc') === 0
    const qxLinkURL = `${REACT_APP_API_BASE_URI}/otc-content/files/OTCQX-Composite-Index-Rules-1515527185671.pdf`
    const qbLinkURL = `${REACT_APP_API_BASE_URI}/otc-content/files/OTCQB-Composite-Index-Rules-1515527176041.pdf`
    return (
      <div className={styles.MAIndexPage}>
        <Breadcrumbs items={[{ title: 'Market Activity', slug: '/market-activity' }, { title: 'OTCM Indices', slug: '/market-activity/indices' }, this.state.quote.data.description || '']} />
        <HeadTitle title={'Index ' + this.state.quote.data.description} />
        <Flexgrid layouts={layouts}>
          <div key='back' className={styles.backLink}>
            <BackLink to='/market-activity/indices' />
          </div>
          <Loading key='info' height='10em' loaded={this.state.quote.loaded}>
            <IndicesHeader quote={this.state.quote.data} />
            <Flex wrap className={styles.info}>
              <Box w={[1, 1, 1 / 3]} className={styles.quoteInfoItem}>
                <label>Previous Close</label>
                <p><Locale type='price'>{this.state.quote.data.previousClose}</Locale></p>
              </Box>
              <Box w={[1, 1, 1 / 3]} className={styles.quoteInfoItem}>
                <label>Open</label>
                <p><Locale type='price'>{this.state.quote.data.openingPrice}</Locale></p>
              </Box>
              <Box w={[1, 1, 1 / 3]} className={styles.quoteInfoItem}>
                <label>Range</label>
                <p><Locale type='price'>{this.state.quote.data.dailyLow}</Locale> - <Locale type='price'>{this.state.quote.data.dailyHigh}</Locale></p>
              </Box>
            </Flex>
            <Outline mode='invisible' spacing='larger'>
              <Chart symbol={this.props.match.params.symbol} />
            </Outline>
            {isOTC && <div className={styles.constituents}>
              <Outline mode='heading'>{this.state.quote.data.description} Constituents</Outline>
              <div className={styles.download}>
                <ExternalLink href={getConstituentsDownload(this.props.match.params.symbol)} target='_blank'><FaDownload /></ExternalLink>
              </div>
              <Loading
                data={this.state.constituents.data}
                reloading={this.state.constituents.reloading}
                loaded={this.state.constituents.loaded}>
                <Table
                  columns={constituentsColumns}
                  data={this.state.constituents.data.records}
                  sortColumn={this.state.constituents.sortOn}
                  sortDirection={this.state.constituents.sortDir}
                  onSorting={sort(this, getConstituents, 'constituents')}
                  showHeader
                />
                <More onClick={expand(this, getConstituents, 'constituents')}
                  disabled={this.state.constituents.expandEmpty} />
              </Loading>
            </div>}
          </Loading>
          {this.props.match.params.symbol.toLowerCase() === '.otcqx' && <div key='links' className={styles.links}>
            <Outline mode='heading'>
              Related Links
            </Outline>
            <div className={styles.linkList}>
              <p>
                <ExternalLink href={qxLinkURL} target='_blank'>OTCQX Composite Rule Book</ExternalLink>
              </p>
            </div>
          </div>}
          {this.props.match.params.symbol.toLowerCase() === '.otcqb' && <div key='links' className={styles.links}>
            <Outline mode='heading'>
              Related Links
            </Outline>
            <div className={styles.linkList}>
              <p>
                <ExternalLink href={qbLinkURL} target='_blank'>OTCQB Composite Rule Book</ExternalLink>
              </p>
            </div>
          </div>}
          <div key='empty' />
        </Flexgrid>
      </div>
    )
  }
}

MAIndexPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      symbol: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
}

export default withRouter(MAIndexPage)
