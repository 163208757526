import moment from 'moment-timezone'

const numberWithCommas = (x) => {
  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export function format (value, placeholder, type, shorten) {
  if (value !== null && value !== undefined) {
    switch (type) {
      case 'int':
        if (typeof value === 'string') value = parseFloat(value)
        value = numberWithCommas(value.toFixed(0))
        break

      case 'seconds':
        if (typeof value === 'string') value = parseFloat(value)
        if (value < 1) value = '< 1s'
        if (value >= 1) value = `${numberWithCommas(value.toFixed(0))}s`
        break

      case 'float':
        if (typeof value === 'string') value = parseFloat(value)
        if (isNaN(value)) return placeholder
        value = numberWithCommas(value.toFixed(2))
        break

      case 'price':
        value = parseFloat(value)
        if (isNaN(value)) return placeholder
        value = numberWithCommas(value.toFixed(6).replace(/0{0,4}$/, ''))
        break

      case 'millions':
        value = parseFloat(value)
        if (isNaN(value)) return placeholder
        value = value / 1000000.0
        value = numberWithCommas(value.toFixed(2))
        break

      case 'percentage':
        value = parseFloat(value)
        if (isNaN(value)) return placeholder
        value = numberWithCommas(value.toFixed(2))
        if (value === '-0.00') value = '0.00'
        if (value.length > 0) value += '%'
        break

      case 'tenths':
        value = parseFloat(value)
        if (isNaN(value)) return placeholder
        value = numberWithCommas(value.toFixed(1))
        if (value === '-0.00') value = '0.00'
        if (value.length > 0) value += '%'
        break

      case 'sign':
        value = parseFloat(value)
        if (isNaN(value)) return placeholder
        const sign = value > 0 ? '+' : ''
        value = numberWithCommas(value.toFixed(2))
        if (value.length > 0) value = sign + value
        break

      case 'bool':
        value = value ? 'Yes' : 'No'
        break

      case 'date':
        value = moment(value).tz('America/New_York').format('MM/DD/YYYY')
        break

      case 'dateList':
        const dateList = value.split(';')
        dateList.forEach((element, index) => {
          dateList[index] = moment(element).tz('America/New_York').format('MM/DD/YYYY')
        })
        value = dateList.join(', ')
        break

      case 'staticDate':
        value = moment(value).format('MM/DD/YYYY')
        break

      case 'textList':
        value = value.split(';').join(', ')
        break

      case 'text':
        break
    }
  } else {
    value = placeholder
  }

  return value
}

export function formatProper (value, placeholder, type, shorten) {
  if (value !== null && value !== undefined) {
    switch (type) {
      case 'int':
        if (typeof value === 'string') value = parseFloat(value)
        value = numberWithCommas(value.toFixed(0))
        break

      case 'seconds':
        if (typeof value === 'string') value = parseFloat(value)
        if (value < 1) value = 0
        if (value >= 1) value = Math.round(value)
        break

      case 'float':
        if (typeof value === 'string') value = parseFloat(value)
        if (isNaN(value)) return placeholder
        value = value.toFixed(2)
        value = parseFloat(value)
        break

      case 'price':
        value = parseFloat(value)
        if (isNaN(value)) return placeholder
        value = numberWithCommas(value.toFixed(6).replace(/0{0,4}$/, ''))
        break

      case 'millions':
        value = parseFloat(value)
        if (isNaN(value)) return placeholder
        value = value / 1000000.0
        value = numberWithCommas(value.toFixed(2))
        break

      case 'percentage':
        value = parseFloat(value)
        if (isNaN(value)) return placeholder
        value = numberWithCommas(value.toFixed(2))
        if (value === '-0.00') value = 0.00
        break

      case 'tenths':
        value = parseFloat(value)
        if (isNaN(value)) return placeholder
        value = value.toFixed(1)
        value = parseFloat(value)
        if (value === '-0.00') value = 0.00
        break

      case 'sign':
        const sign = parseFloat(value) > 0 ? '+' : ''
        value = numberWithCommas(value.toFixed(2))
        if (value.length > 0) value = sign + value
        break

      case 'bool':
        value = value ? 'Yes' : 'No'
        break

      case 'date':
        value = moment(value).tz('America/New_York').format('MM/DD/YYYY')
        break

      case 'staticDate':
        value = moment(value).format('MM/DD/YYYY')
        break

      case 'text':
        break
    }
  } else {
    value = placeholder
  }

  return value
}

export const checkNegative = value => {
  const NA = 'N/A'

  if (typeof value === 'string') {
    if (value.includes('-')) return NA
  }

  if (typeof value === 'number') {
    if (Math.sign(value) === -1) return NA
  }

  return value
}
