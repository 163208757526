import React from 'react'
import PropTypes from 'prop-types'
import equal from 'fast-deep-equal'
import { withRouter } from '../../components/WithRouter'
import styles from './MAClosingSummaryPage.module.scss'
import { template, fetch, expand, sort } from '../../api/helper'
import {
  getSnapshot,
  getMostActive,
  getAdvancers,
  getDecliners,
  downloadMostActive,
  downloadAdvancers,
  downloadDecliners
} from '../../api/otc/market-activity/closing-summary'

import HeadTitle from '../../components/HeadTitle'
import MarketActivityOverview from '../../components/MarketActivityOverview'
import Flexgrid from '../../components/Flexgrid'
import Outline from '../../components/Outline'
import Table from '../../components/Table'
import TableFooter from '../../components/TableFooter'
import DisplayResults from '../../components/DisplayResults'
import More from '../../components/More'
import Loading from '../../components/Loading'
import TableTabs from '../../components/TableTabs'
import PageTitle from '../../components/PageTitle'
import BackLink from '../../components/BackLink'
import Breadcrumbs from '../../components/Breadcrumbs'
import Sticky from '../../components/Sticky'

// Mapping functions to table names
const tableFunctions = {
  active: getMostActive,
  advancers: getAdvancers,
  decliners: getDecliners
}

const downloadFunctions = {
  active: downloadMostActive,
  advancers: downloadAdvancers,
  decliners: downloadDecliners
}

/** Headers for the Table */
const tableColumns = [
  {
    name: 'symbol',
    header: 'Symbol',
    headerShort: 'Sym',
    type: 'symbol'
  },
  {
    name: 'price',
    header: 'Price',
    type: 'price'
  },
  {
    name: 'pctChange',
    header: '% Change',
    type: 'sign'
  },
  {
    key: 'dollarVolume',
    name: 'dollarVolume',
    header: '$ Vol',
    type: 'int',
    hideOnMobile: false
  },
  {
    key: 'volume',
    name: 'shareVolume',
    header: 'Share Vol',
    type: 'int',
    hideOnMobile: true
  },
  {
    key: 'tradeCount',
    name: 'tradeCount',
    header: 'Trades',
    type: 'int',
    hideOnMobile: true
  }
]

class MAClosingSummaryPage extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      loaded: false,
      snapshot: template('object'),
      active: template('records'),
      advancers: template('records'),
      decliners: template('records'),
      selectedTab: {
        active: 'dollarVolume',
        advancers: '1',
        decliners: '1'
      }
    }
  }

  /**
   * Load Initial Data
   */
  componentDidMount () {
    const { match: { params: { table, tab } } } = this.props

    // set selected tab
    this.setState({
      ...this.state,
      selectedTab: {
        ...this.state.selectedTab,
        [table]: tab
      }
    }, () => {
      this.loadSnapshot()
      this.loadData()
    })
  }

  /**
   * When the component updates, check if the params changed, and requery the data if so.
   * @param {Object} prevProps - The previous props to compare to.
   */
  componentDidUpdate (prevProps) {
    const { match: { params: prevParams } } = prevProps
    const { match: { params } } = this.props

    if (!equal(params, prevParams)) {
      if ((params.market || 'ALL') !== (prevParams.market || 'ALL')) this.loadSnapshot()
      this.loadData()
    }
  }

  /**
   * Mapping snapshot data to have better headers displayed.
   * @param {Array[Object]} data - Snapshot data
   */
  getInfoFromData = (data) => {
    const info = []
    if (!data) return info
    Object.keys(data).forEach((key) => {
      const item = { key, value: data[key], title: '' }

      if (Array.isArray(item.value) || !item.value) {
        item.value = 0
      }

      switch (key) {
        case 'dollarVolume':
          item.title = '$ Volume'
          break
        case 'shareVolume':
          item.title = 'Share Volume'
          break
        case 'trades':
        case 'advancers':
        case 'decliners':
          item.title = item.key.charAt(0).toUpperCase() + item.key.slice(1)
          break
      }
      info.push(item)
    })
    return info
  }

  /**
   * Loads snapshot data
   * @type {[type]}
   */
  loadSnapshot = () => {
    const { match: { params: { market } } } = this.props

    // load the snapshot data and parse it
    fetch(this, getSnapshot, 'snapshot', {
      tierGroup: market
    }, data => {
      if (!data) data = {}
      data.info = this.getInfoFromData(data)
      return data
    })
  }

  /**
   * Loads the necessary data for the snapshot and table(s).
   * @param {string} priceMin - Filter the query based on minimum price. (Options are 1, 0.05, and 0.01)
   * @param {string} forceTable - Specific table to query the data from.
   */
  loadData = (priceMin, forceTable) => {
    const { match: { params: { market, table, tab } } } = this.props
    let tempTable = table
    if (forceTable) tempTable = forceTable

    const loadTable = (tableName) => {
      this.setState({ [tableName]: template('records') }, () => {
        fetch(this, tableFunctions[tableName], tableName, {
          tierGroup: market,
          page: 1,
          pageSize: table ? 25 : 10,
          priceMin: tempTable === 'active' ? undefined : (priceMin || tab),
          sortOn: tempTable === 'active' ? (this.state.selectedTab[tableName] || tab) : undefined
        })
      })
    }

    // We want to load basic data for all the tables
    // if a table is not selected
    if (!tempTable) {
      for (const key in tableFunctions) {
        loadTable(key)
      }
    } else {
      // A table was selected, so load the specific table data.
      loadTable(tempTable)
    }
  }

  /**
   * Generates the route to navigate to when the user clicks on the 'More' button.
   * @param {string} market - The market to query on.
   * @param {string} table - The table to query on.
   */
  routeFor (market, table) {
    const tab = this.state.selectedTab[table]
    return `/market-activity/closing-summary/${market}/${table}/${tab}`
  }

  /**
   * Generate Tabs for the non-expanded tables. Initial view.
   * @param {string} table - The table the user has selected.
   */
  getTabsArrayFor (table) {
    let tabs = table === 'active'
      ? [{
          key: 'price',
          title: 'Price'
        }, {
          key: 'pricePct',
          title: '% Change'
        }, {
          key: 'dollarVolume',
          title: '$ Volume'
        }, {
          key: 'volume',
          title: 'Share Volume'
        }, {
          key: 'tradeCount',
          title: 'Trades'
        }]
      : [{
          key: '1',
          title: 'Over $1'
        }, {
          key: '0.05',
          title: 'Over $0.05'
        }, {
          key: '0',
          title: 'All'
        }]

    tabs = tabs.map((tab) => {
      tab.func = () => {
        this.setState({
          ...this.state,
          selectedTab: {
            ...this.state.selectedTab,
            [table]: tab.key
          }
        }, () => {
          this.loadData(table === 'active' ? null : tab.key, table)
        })
      }
      return tab
    })

    return tabs
  }

  /**
   * Navigate to requery and change the market selected.
   * @param {string} market - The market to change to.
   */
  changeMarket = (market) => {
    const { match: { params: { table, tab } } } = this.props
    let location = '/market-activity/closing-summary'
    if (market) {
      location += '/' + market
    }
    if (table) {
      if (!market) {
        location += '/all'
      }
      location += '/' + table
    }
    if (tab) {
      location += '/' + tab
    }
    this.props.navigate(location)
  }

  /**
   * Update table columns array to render properly on mobile
   * @param {array} table - array to search
   */

  checkTableColumn = (table) => {
    let tableCols = table.map(x => ({ ...x }))
    tableCols = tableCols.map((col) => {
      col.hideOnMobile = typeof col.hideOnMobile !== 'undefined' && col.key !== this.state.selectedTab.active
      return col
    })

    return tableCols
  }

  handleDownload = (tableName) => {
    const { match: { params: { market } } } = this.props
    const selectedTab = this.state.selectedTab[tableName]
    const filter = tableName === 'active' ? { sortOn: selectedTab } : { priceMin: selectedTab }
    downloadFunctions[tableName]({
      tierGroup: market,
      ...filter
    })
  }

  render () {
    const { match: { params: { market, table, tab } } } = this.props
    const { active, advancers, decliners } = this.state
    const layouts = {
      sm: [
        {
          type: 'flex',
          column: true,
          subtree: ['overview', 'active', 'advancers', 'decliners']
        }
      ],
      md: [
        {
          type: 'flex',
          column: true,
          subtree: ['overview', 'active', 'advancers', 'decliners', 'footer-note']
        }
      ],
      lg: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'side',
              subtree: ['overview']
            },
            {
              type: 'main',
              subtree: ['active', 'advancers', 'decliners', 'footer-note']
            }
          ]
        }
      ]
    }

    const overviewLayouts = {
      sm: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'flex',
              column: true,
              width: 1 / 2,
              className: styles.overviewLeft,
              subtree: ['dollarVolume', 'trades', 'decliners']
            },
            {
              type: 'flex',
              column: true,
              width: 1 / 2,
              className: styles.overviewRight,
              subtree: ['shareVolume', 'advancers']
            }
          ]
        }
      ],
      md: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'flex',
              column: true,
              width: 1 / 2,
              className: styles.overviewLeft,
              subtree: ['dollarVolume', 'trades', 'decliners']
            },
            {
              type: 'flex',
              column: true,
              width: 1 / 2,
              className: styles.overviewRight,
              subtree: ['shareVolume', 'advancers']
            }
          ]
        }
      ],
      lg: [
        {
          type: 'flex',
          column: true,
          subtree: ['dollarVolume', 'shareVolume', 'trades', 'advancers', 'decliners']
        }
      ]
    }

    /** Needed to acquire the data */
    const selectedMarket = market || 'ALL'
    const selectedTable = table || 'active'

    if (!table && !tab) {
      return (
        <div className={styles.MACurrentMarketPage}>
          <HeadTitle title='Stock Market Activity | Closing Summary' />
          <Breadcrumbs items={[{ title: 'Market Activity', slug: '/market-activity' }, 'Closing Summary']} />
          <PageTitle>Closing Summary</PageTitle>
          <Flexgrid layouts={layouts}>
            <Loading
              key='overview'
              emptyMessage='No snapshot data available'
              loaded={this.state.snapshot.loaded}
              error={this.state.snapshot.error}
              data={this.state.snapshot.data.info}>
              <Sticky className={styles.sideColumn}>
                {this.state.snapshot.loaded && <MarketActivityOverview
                  selectedMarket={market}
                  changeMarket={this.changeMarket}
                  info={this.state.snapshot.data.info}
                  date={this.state.snapshot.data.lastUpdated}
                  hideTime
                  layouts={overviewLayouts} />}
              </Sticky>
            </Loading>
            <div key='active' className={styles.tradeDataTable}>
              <Outline mode='heading'>Most Active</Outline>
              <TableTabs
                tabs={this.getTabsArrayFor('active')}
                selectedTab={this.state.selectedTab.active}
                download={() => this.handleDownload('active')} />
              <Loading
                type='table'
                loaded={active.loaded}
                error={active.error}
                data={active.data.records}>
                <Table
                  columns={this.checkTableColumn(tableColumns)}
                  data={active.data.records}
                  sortColumn={active.sortOn}
                  sortDirection={active.sortDir}
                  onSorting={sort(this, tableFunctions.active, 'active')}
                  showHeader
                />
                <TableFooter>
                  <More to={this.routeFor(selectedMarket, 'active')} />
                  {active.data.records && active.data.totalRecords &&
                    <DisplayResults show={active.data.records.length} total={active.data.totalRecords} text={'Most Active'} />
                  }
                </TableFooter>
              </Loading>
            </div>
            <div key='advancers' className={styles.tradeDataTable}>
              <Outline mode='heading'>Advancers</Outline>
              <TableTabs
                tabs={this.getTabsArrayFor('advancers')}
                selectedTab={this.state.selectedTab.advancers}
                download={() => this.handleDownload('advancers')} />
              <Loading
                type='table'
                loaded={advancers.loaded}
                error={advancers.error}
                data={advancers.data.records}>
                <Table
                  columns={tableColumns}
                  data={advancers.data.records}
                  sortColumn={advancers.sortOn}
                  sortDirection={advancers.sortDir}
                  onSorting={sort(this, tableFunctions.advancers, 'advancers')}
                  showHeader
                />
                <TableFooter>
                  <More to={this.routeFor(selectedMarket, 'advancers')} />
                  {advancers.data.records && advancers.data.totalRecords &&
                    <DisplayResults show={advancers.data.records.length} total={advancers.data.totalRecords} text={'Advancers'} />
                  }
                </TableFooter>
              </Loading>
            </div>
            <div key='decliners' className={styles.tradeDataTable}>
              <Outline mode='heading'>Decliners</Outline>
              <TableTabs
                tabs={this.getTabsArrayFor('decliners')}
                selectedTab={this.state.selectedTab.decliners}
                download={() => this.handleDownload('decliners')} />
              <Loading
                type='table'
                loaded={decliners.loaded}
                error={decliners.error}
                data={decliners.data.records}>
                <Table
                  columns={tableColumns}
                  data={decliners.data.records}
                  sortColumn={decliners.sortOn}
                  sortDirection={decliners.sortDir}
                  onSorting={sort(this, tableFunctions.decliners, 'decliners')}
                  showHeader
                />
                <TableFooter>
                  <More to={this.routeFor(selectedMarket, 'decliners')} />
                  {decliners.data.records && decliners.data.totalRecords &&
                    <DisplayResults show={decliners.data.records.length} total={decliners.data.totalRecords} text={'Decliners'} />
                  }
                </TableFooter>
              </Loading>
            </div>
            <div key='footer-note' className={styles.footerNote}>
              <sup>1</sup>
              Intraday activity delayed 15 minutes. Closing data is displayed from 4 PM to 9 AM on trading days
               and all day on Saturday, Sunday and Holidays.
            </div>
          </Flexgrid>
        </div>
      )
    } else {
      let tableTitle
      switch (selectedTable) {
        case 'advancers':
          tableTitle = 'Advancers'
          break
        case 'decliners':
          tableTitle = 'Decliners'
          break
        case 'active':
        default:
          tableTitle = 'Most Active'
          break
      }
      return (
        <div className={styles.MACurrentMarketPage}>
          <HeadTitle title='Market Activity - Closing Summary' />
          <Breadcrumbs items={[
            { title: 'Market Activity', slug: '/market-activity' },
            { title: 'Closing Summary', slug: `/market-activity/closing-summary/${this.props.match.params.market}` },
            tableTitle]} />
          <PageTitle>Closing Summary</PageTitle>
          <div className={styles.back}>
            <BackLink to={`/market-activity/closing-summary/${this.props.match.params.market}`} ignoreHistory />
          </div>
          <Flexgrid layouts={layouts}>
            <Loading
              key='overview'
              emptyMessage='No snapshot data available'
              loaded={this.state.snapshot.loaded}
              error={this.state.snapshot.error}
              data={this.state.snapshot.data.info}>
              <Sticky className={styles.sideColumn}>
                {this.state.snapshot.loaded && <MarketActivityOverview
                  selectedMarket={market}
                  changeMarket={this.changeMarket}
                  info={this.state.snapshot.data.info}
                  date={this.state.snapshot.data.lastUpdated}
                  layouts={overviewLayouts} />}
              </Sticky>
            </Loading>
            <div key='active' className={styles.tradeDataTable}>
              <Outline mode='heading'>{tableTitle}</Outline>
              <TableTabs
                tabs={this.getTabsArrayFor(table)}
                selectedTab={this.state.selectedTab[table]}
                download={() => this.handleDownload(table)} />
              <Loading
                type='table'
                height='80em'
                loaded={this.state[table].loaded}
                reloading={this.state[table].reloading}
                error={this.state[table].error}
                data={this.state[table].data.records}>
                <Table
                  columns={table === 'active' ? this.checkTableColumn(tableColumns) : tableColumns}
                  data={this.state[table].data.records}
                  sortColumn={this.state[table].sortOn}
                  sortDirection={this.state[table].sortDir}
                  onSorting={sort(this, tableFunctions[table], table)}
                  showHeader
                />
                <TableFooter>
                  <More onClick={expand(this, tableFunctions[table], table)}
                    disabled={this.state[table].expandEmpty} />
                  {this.state[table].data.records && this.state[table].data.totalRecords &&
                    <DisplayResults show={this.state[table].data.records.length} total={this.state[table].data.totalRecords} text={'Securities'} />
                  }
                </TableFooter>
              </Loading>
            </div>
            <div key='footer-note' className={styles.footerNote}>
              <sup>1</sup>
              Intraday activity delayed 15 minutes. Closing data is displayed from 4 PM to 9 AM on trading days
              and all day on Saturday, Sunday and Holidays.
            </div>
          </Flexgrid>
        </div>
      )
    }
  }
}

MAClosingSummaryPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      market: PropTypes.string,
      table: PropTypes.string,
      tab: PropTypes.string
    })
  }),
  navigate: PropTypes.func.isRequired
}

export default withRouter(MAClosingSummaryPage)
