export const HOME = '/'
export const SEARCH = '/search'
export const CONTACT = '/contact'
export const DIR_WHY_OTCM = '/corporate-services/why-otc-markets-group'
export const DIR_BROKER_DEALER = '/directories/broker-dealer-directory'
export const DIR_COMPANY = '/directories/company-directory'
export const DIR_QUALIFIED_FEXCHANGE = '/directories/qualified-foreign-exchange'
export const DIR_STATE_SEC_COMMISSIONS = '/directories/state-securities-commissions'
export const DIR_SPONSORS = '/directories/sponsors'
export const OLD_DIR_SPONSORS = '/directories/otcqx-sponsors'
export const DIR_SERVICE_PROVIDER = '/directories/service-providers'
export const DIR_PROH_SERVICE_PROVIDERS = '/directories/prohibited-service-providers'
export const LEARN_FAQ = '/learn/faqs'
export const LEARN_BLUE_SKY_DATA = '/learn/blue-sky-rules'
export const FIXED_INCOME = '/fixed-income'
export const DIR_PREMIUM_PROVIDERS = '/directories/premium-provider-directory'