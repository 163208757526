import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import styles from './CompanyQuotePage.module.scss'
import classNames from 'classnames'
import { Flex, Box } from 'grid-styled'
import { withRouter } from '../../components/WithRouter'
import { withTheme } from 'styled-components'
import { Link, Navigate } from 'react-router-dom'
import { template, fetch } from '../../api/helper'
import {
  getFullCompanyProfile,
  getCompanyBadges,
  getFixedIncome
} from '../../api/otc/company/profile'
import {
  getInsideData
} from '../../api/otc/stock/trades'
import {
  getAdvancers
} from '../../api/otc/market-activity/current-market'
import { getLatest } from '../../api/otc/compliance/stats'
import NotFound from '../NotFound'
import HeadTitle from '../../components/HeadTitle'
import CompanyQuoteMenu from '../../components/CompanyQuoteMenu'
import Loading from '../../components/Loading'
import { companyQuoteTabs } from '../../menus'
import Flexgrid from '../../components/Flexgrid'
import SymbolLink from '../../components/SymbolLink'
import Outline from '../../components/Outline'
import Tooltip from '../../components/Tooltip'
import Hide from '../../components/Hide'
import RiskScore from '../../components/RiskScore'
import Locale from '../../components/Locale'
import { FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa'
import { FIXED_INCOME } from '../../constants/Routes'
import { withContext } from '../../AuthContext'
import { OTC_ANALYTICS, SMALL_CAP_LISTED } from '../../constants/groups'

const CompanyQuoteHeader = ({ company, info = {}, inside, loaded }) => {
  const noInside = !info.hasQuotes || (!inside.bidPrice && !inside.askPrice)
  const unlisted = !info.tierGroup

  return <Flex wrap>
    <Flex column w={[1, 1 / 2]} className={styles.header}>
      <h2>{company.toUpperCase()}</h2>
      <p className={styles.companyName}>{info.name || info.companyname || info.securityName}</p>
      <div className={styles.subHeader}>
        {info.currentSecurity && info.currentSecurity.className}
        {info.exchangeName && `Venue: ${info.exchangeName}`}
        {info.currentSecurity && info.currentSecurity.statusName && <span>&nbsp;| Status:&nbsp;
          <span className={classNames({ [styles.suspendedStatus]: (info.currentSecurity.statusName === 'Suspended' || info.currentSecurity.statusName === 'Halted') || info.currentSecurity.statusName === 'Internal Halt' })}>
            {info.currentSecurity.statusName}
          </span>
        </span>}
      </div>
    </Flex>
    {info.isFixedIncome && <Flex column w={[1, 1 / 2]} className={styles.bid}>
      <div className={styles.subHeader}>
        <h2 />
        {info.securityDesc && <div>
          Security Description: {info.securityDesc}
        </div>}
        {info.couponRate && <div>
          Coupon Rate: <Locale type='percentage'>{info.couponRate}</Locale>
        </div>}
        {info.maturityDate && <div>
          Maturity Date: {moment(info.maturityDate).tz('America/New_York').format('MM/DD/YYYY')}
        </div>}
        <div>
          144A Status: {info.flag144a ? 'Yes' : 'No'}
        </div>
      </div>
    </Flex>}
    {loaded && <Flex column w={[1, 1 / 2]} className={styles.bid}>
      <Flex justify={['flex-start', 'flex-end']} className={classNames(styles.bidline, {
        [styles.positive]: inside.change > 0,
        [styles.negative]: inside.change < 0,
        [styles.noChange]: !(inside.change !== undefined && (inside.change > 0 || inside.change < 0))
      })}>
        <h2><Locale type='price'>{inside.lastSale}</Locale></h2>
        {inside.change > 0 && <FaLongArrowAltUp />}
        {inside.change < 0 && <FaLongArrowAltDown />}
        {inside.change !== undefined && <Flex column className={styles.evo}>
          <p className={styles.evoNumber}>{inside.change !== undefined ? <Locale type='price'>{inside.change}</Locale> : ''}</p>
          <p className={styles.evoPercent}>{inside.change !== undefined ? <Locale type='percentage'>{inside.percentChange}</Locale> : ''}</p>
        </Flex>}
      </Flex>
      {(noInside && !unlisted) && <p className={styles.ask}>
        <strong>No Inside Bid / Offer</strong>
      </p>}
      {(!noInside && !unlisted) && <p className={styles.ask}>
        <strong>{inside.bidPrice
          ? <Locale type='price'>{inside.bidPrice}</Locale>
          : 'No Inside'} / {inside.askPrice
            ? <Locale type='price'>{inside.askPrice}</Locale>
            : 'No Inside'}
        </strong> {
          (inside.bidSize && inside.askSize) && <span>
            ({inside.bidSize} x {inside.askSize})
          </span>
        }
      </p>}
      <div className={styles.subHeader}>
        {inside.insideTime && <div>
          Real-Time Best Bid & Ask: {moment(inside.insideTime).tz('America/New_York').format('hh:mma MM/DD/YYYY')}
        </div>}
        {inside.quoteDateTime && <div>
          Delayed (15 Min) Trade Data: {moment(inside.quoteDateTime).tz('America/New_York').format('hh:mma MM/DD/YYYY')}
        </div>}
      </div>
    </Flex>}
  </Flex>
}

CompanyQuoteHeader.propTypes = {
  company: PropTypes.string.isRequired,
  info: PropTypes.object.isRequired,
  inside: PropTypes.object.isRequired,
  loaded: PropTypes.bool
}

const initialState = {
  invalidSymbol: false,
  info: {
    loaded: false,
    data: {},
    error: null
  },
  badges: {
    loaded: false,
    data: {},
    error: null
  },
  inside: {
    loaded: false,
    data: {},
    error: null
  },
  advancersqx: template('records'),
  advancersqb: template('records'),
  riskScoreLatest: template('records')
}

const getAllowedTabs = pathname => {
  const isFixedIncome = pathname.includes(FIXED_INCOME)
  return companyQuoteTabs.filter(item => {
    if (isFixedIncome && item.showFixedIncome) return item
    if (!isFixedIncome && item.showStock) return item
  }).map(item => item.tab)
}
class CompanyQuotePage extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      ...initialState,
      allowedTabs: getAllowedTabs(props.location.pathname)
    }
  }

  getData (func, objectName, symbol, formatting) {
    func(symbol)
      .then(data => {
        if (formatting) {
          data = formatting(data)
        }

        this.setState({
          [objectName]: {
            loaded: true,
            error: null,
            data
          }
        })
      })
      .catch((err) => {
        console.error(err)
        if (err.response && err.response.data && err.response.data.message &&
            err.response.data.message.indexOf('not supported OTC symbol') > -1) {
          return this.setState({ invalidSymbol: true })
        }
        this.setState(state => ({
          [objectName]: {
            loaded: false,
            error: 'Something went wrong, please try again.',
            data: state[objectName].data
          }
        }))
      })
  }

  componentDidMount () {
    const { match: { params: { company } }, location: { pathname } } = this.props
    if (pathname.includes(FIXED_INCOME)) {
      this.loadFixedIncomeData(company, 'info')
    } else {
      this.loadData(company)
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const pathname = nextProps.location.pathname
    const currentSymbol = this.props.match.params.company
    const nextSymbol = nextProps.match.params.company
    if (nextSymbol !== currentSymbol) {
      console.log('HERE')
      // reset state before loading
      this.setState({ ...initialState, allowedTabs: getAllowedTabs(pathname) }, () => {
        if (pathname.includes(FIXED_INCOME)) {
          this.loadFixedIncomeData(nextSymbol, 'info')
        } else {
          this.loadData(nextSymbol)
        }
      })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { match: { params: { company } } } = this.props

    if (prevState.info.loaded !== this.state.info.loaded && this.state.info.loaded) {
      const user = this.props.context.user
      const isOtcAnalyticsEntitled = user && user.userGroups && user.userGroups.find(group => (group === OTC_ANALYTICS))
      const isSmallCapEntitled = user && user.userGroups && user.userGroups.find(group => (group === SMALL_CAP_LISTED))
      const isRiskScoreEligible = (this.state.info.data.isEquity && isOtcAnalyticsEntitled) || (this.state.info.data.isListed && isSmallCapEntitled)

      if (isRiskScoreEligible) {
        fetch(this, getLatest, 'riskScoreLatest', {
          symbol: company
        })
      }
    }
  }

  loadData = (symbol) => {
    // get company information
    this.getData(getFullCompanyProfile, 'info', symbol, data => {
      let currentSecurity = {}
      if (data && data.securities) {
        currentSecurity = data.securities.find(security => security.symbol.toLowerCase() === symbol.toLowerCase())
      }

      // don't allow invalid phone numbers
      if (data.phone && data.phone.length < 7) data.phone = null

      // Ensure indices sort order
      if (data.indexStatuses && data.indexStatuses.length > 0) {
        const indexOrder = ['.OTCQX', '.OTCQXBIL', '.OTCQXDIV', '.OTCQXBK', '.OTCQXINT', '.OTCQXCAN', '.OTCQXMJ', '.OTCQXUS']
        data.indexStatuses.sort((a, b) => indexOrder.indexOf(a.indexSymbol) - indexOrder.indexOf(b.indexSymbol))
      }

      // set flags for content display, etc.
      const hasQuotes = !(data.isCaveatEmptor && (data.tierGroup === 'OO' || currentSecurity.tierId === 21 || currentSecurity.tierId === 22))
      const isOTC = data.tierGroup === 'QX' || data.tierGroup === 'DQ' || data.tierGroup === 'PS' || data.tierGroup === 'EM'
      const isEquity = data.tierGroup
      const isListed = !data.tierGroup
      const isQxQB = !!(data.tierGroup && (data.tierGroup === 'QX' || data.tierGroup === 'DQ'))
      const isGrey = !!(data.tierGroup && data.tierGroup === 'OO')

      // set overview/quote warning
      let warning = null
      if (data.tierGroup === 'OO') warning = 'grey'
      if (currentSecurity.tierId === 21) warning = 'limited-info'
      if (data.isBankrupt) warning = 'bankrupt'
      if (data.isUnsolicited) warning = 'unsolicited'
      if (currentSecurity.tierId === 22) warning = 'no-info'
      if (data.isCaveatEmptor && !hasQuotes) warning = 'caveat-emptor'

      return { ...data, currentSecurity, warning, isOTC, isEquity, isListed, hasQuotes, isQxQB, isGrey }
    })

    // get company badges
    this.getData(getCompanyBadges, 'badges', symbol)

    // get inside data
    this.getData(getInsideData, 'inside', symbol)

    // get daily advancers
    fetch(this, getAdvancers, 'advancersqx', {
      tierGroup: 'QX',
      page: 1,
      pageSize: 5,
      sortOn: 'pctChange',
      priceMin: 0
    })
    fetch(this, getAdvancers, 'advancersqb', {
      tierGroup: 'DQ',
      page: 1,
      pageSize: 5,
      sortOn: 'pctChange',
      priceMin: 0
    })

    // update quote every 2 minutes
    clearInterval(this.timer)
    this.timer = setInterval(() => {
      this.getData(getInsideData, 'inside', symbol)
    }, 2 * 60 * 1000)
  }

  loadFixedIncomeData (symbol, objectName) {
    // Check if Fixed Income
    getFixedIncome(symbol).then(data => {
      this.setState({
        [objectName]: {
          loaded: true,
          error: null,
          data: data.data
        }
      })
    }).catch(err => {
      console.error(err)
      if (err.response && err.response.data && err.response.data.message &&
          err.response.data.message.indexOf('not supported OTC symbol') > -1) {
        return this.setState({ invalidSymbol: true })
      }
      this.setState(state => ({
        [objectName]: {
          loaded: false,
          error: 'Something went wrong, please try again.',
          data: state[objectName].data
        }
      }))
    })
  }

  componentWillUnmount () {
    clearInterval(this.timer)
  }

  render () {
    const { match: { params: { company, tab, contentType, contentId } }, location: { pathname } } = this.props

    if (this.state.invalidSymbol) return <NotFound />

    if (!tab) {
      if (pathname.includes(FIXED_INCOME)) {
        const userGroups = this.props && this.props.context && this.props.context.user && this.props.context.user.userGroups ? this.props.context.user.userGroups : []
        let fiDefaultTab = 'blue-sky'

        if (userGroups.includes('fixedIncome211') && !userGroups.includes('blueSkyFixedIncome')) {
          fiDefaultTab = 'fi-211'
        }

        return <Navigate to={`${FIXED_INCOME}/${company}/${fiDefaultTab}`} />
      } else {
        return <Navigate to={`/stock/${company}/overview`} />
      }
    } else {
      if (!this.state.allowedTabs.includes(tab)) return <NotFound />
    }

    const TabComponent = companyQuoteTabs.find(item => item.tab === tab).component
    const layouts = {
      sm: [
        {
          type: 'flex',
          column: true,
          subtree: ['messages', 'header', 'profile', 'flags',
            'content', 'risk-score', 'related-securities']
        }
      ],
      md: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'flex',
              column: true,
              width: 1,
              subtree: ['messages', 'header', {
                type: 'flex',
                subtree: [{
                  type: 'box',
                  column: true,
                  width: 1 / 2,
                  className: styles.leftColumnProfile,
                  subtree: ['profile']
                },
                {
                  type: 'box',
                  flex: '1 1 auto',
                  className: styles.rightColumnFlags,
                  subtree: ['empty']
                },
                {
                  type: 'box',
                  column: true,
                  className: styles.rightColumnFlags,
                  subtree: ['flags']
                }]
              }, {
                type: 'box',
                column: true,
                subtree: ['risk-score']
              },
              'content', {
                type: 'flex',
                subtree: [{
                  type: 'box',
                  column: true,
                  width: 1 / 2,
                  className: styles.leftColumnFirst,
                  subtree: ['related-securities']
                }]
              }]
            }
          ]
        }
      ],
      lg: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'main',
              subtree: ['header', 'content']
            },
            {
              type: 'side',
              subtree: ['profile', 'tier', 'flags', 'indexes', 'messages', 'total-score', 'risk-score', 'related-securities']
            }
          ]
        }
      ]
    }

    const info = this.state.info.data
    const inside = this.state.inside.data

    let accentColor = null
    let tierName = null

    if (this.state.info.loaded) {
      switch (info.tierGroup) {
        case 'QX':
          tierName = 'OTCQX'
          accentColor = 'green'
          break

        case 'DQ':
          tierName = 'OTCQB'
          accentColor = 'orange'
          break

        case 'PS':
          tierName = 'Pink'
          accentColor = 'pink'
          break

        case 'OO':
          tierName = 'Grey Market'
          accentColor = 'gray'
          break

        case 'EM':
          tierName = 'OTC Expert Market'
          accentColor = 'gray'
          break

        default:
          tierName = info.venue || 'Listed'
          accentColor = 'black'
          break
      }

      if (info.isFixedIncome) {
        tierName = info.venue
        accentColor = 'green'

        switch (info.exchange) {
          case 'O':
            info.exchangeName = 'OTC'
            break
          case 'NL':
            info.exchangeName = 'NYSE'
            break
          case 'NU':
            info.exchangeName = 'NYSE Unlisted'
            break
          case 'NA':
            info.exchangeName = 'NYSE American'
            break
          default:
            info.exchangeName = null
            break
        }
      }
    }

    let tierImage = null
    let tierDesc = null
    switch (info.currentSecurity && info.currentSecurity.tierId) {
      case 1:
      case 2:
      case 5:
      case 6:
        tierImage = 'QX'
        break

      case 10:
        tierImage = 'QB'
        break

      case 20:
        tierImage = 'PS'
        break

      case 21:
        tierImage = 'yield'
        tierDesc = 'Companies that may have limited disclosure or financial information publicly available, but meet a minimum requirement for public quoting under Rule 15c2-11.'
        break

      case 22:
        tierImage = 'stop'
        tierDesc = 'OTC Markets Group is unable to confirm this company is providing public disclosure to a regulator, an exchange, or OTC Markets Group.'
        break

      case 30:
        tierImage = 'caution'
        tierDesc = 'Grey Market securities are securities that broker-dealer\'s are unwilling or unable to provide electronic bid/ask quotes.'
        break

      case 40:
        tierImage = 'expert-tier'
        break

      default:
        break
    }
    if (info.isCaveatEmptor) {
      tierImage = 'caveat-emptor'
    }

    const tierImageUrl = tierImage ? `/logos/tier/${tierImage}.png` : null

    // Awards
    let awardImage
    if (info.tierGroup === 'QX' && info.otcAward && info.otcAward.best50) {
      awardImage = '/logos/OTCQX_Best_50.png'
    }
    if (info.tierGroup === 'QX' && info.otcAward && info.otcAward.tenYear) {
      awardImage = '/logos/OTCQX_10_Years.png'
    }

    const showTierDisplayName = info.currentSecurity && info.currentSecurity.tierDisplayName && info.currentSecurity.tierId && info.currentSecurity.tierId !== 0
    const flags = []
    let basicInfo = null

    if (this.state.info.loaded) {
      // Tier joined
      switch (info.tierGroup) {
        // OTCQX
        case 'QX':
          basicInfo = `Joined OTCQX ${moment(info.tierStartDate).format('MM/YYYY')}`
          break

        // OTCQB
        case 'DQ':
          basicInfo = `Joined OTCQB ${moment(info.tierStartDate).format('MM/YYYY')}`
          break
      }

      // Tier name
      tierName = <Tooltip
        text={info.tierGroup === 'EM' ? 'This market serves broker-dealer pricing and investor best execution needs. Quotations in Expert Market securities are restricted from public viewing.' : ''}
        keepTooltip
        width='260px'
        placement='bottom'>
        {(showTierDisplayName && info.currentSecurity.tierDisplayName) || tierName}
      </Tooltip>
    }

    // Badges
    if (this.state.badges.loaded) {
      const profileBadges = this.state.badges.data

      if (profileBadges) {
        if (profileBadges.hasGracePeriod && (info.tierGroup === 'QX' || info.tierGroup === 'DQ' || info.tierGroup === 'PS')) {
          flags.push({
            title: 'Grace Period',
            icon: 'grace-period',
            color: 'yellow',
            glossaryLink: 'grace-period',
            tooltip: "This security has entered a 15 calendar day Grace Period before it is downgraded to the Expert Market because OTC Markets Group is unable to confirm that the issuer's disclosure is current and publicly available under Rule 15c2-11."
          })
        }
        if (profileBadges.isDelinquent) {
          flags.push({
            title: 'Delinquent SEC Reporting',
            icon: 'delinquent',
            color: 'red',
            glossaryLink: 'delinquent-sec-reporting',
            tooltip: 'Company is not current in its reporting obligations under Section 13 or 15(d) of the Exchange Act.'
          })
        }
        if (profileBadges.isDark) {
          if (info.isQxQB && info.isAlternativeReporting) {
            flags.push({
              title: 'Delinquent in Filings',
              icon: 'dark',
              color: 'red',
              glossaryLink: 'dark-or-defunct',
              tooltip: 'OTC Markets Group is unable to confirm this company is providing public disclosure to a regulator, an exchange, or OTC Markets Group.'
            })
          } else {
            flags.push({
              title: 'Dark or Defunct',
              icon: 'dark',
              color: 'red',
              glossaryLink: 'dark-or-defunct',
              tooltip: 'OTC Markets Group is unable to confirm this company is providing public disclosure to a regulator, an exchange, or OTC Markets Group.'
            })
          }
        }
        if (profileBadges.isCaveatEmptor) {
          flags.push({
            title: 'Caveat Emptor',
            icon: 'caveat-emptor',
            glossaryLink: 'caveat-emptor',
            tooltip: 'Buyer Beware: OTC Markets Group has determined that there is a public interest concern associated with the company.'
          })
        }
        if (profileBadges.isHotSector) {
          flags.push({
            title: 'Hot Industry',
            icon: 'fire',
            color: 'red',
            glossaryLink: '',
            tooltip: 'This security is in a volatile industry (e.g. cannabis).'
          })
        }
        if (profileBadges.hasPromotion) {
          flags.push({
            title: 'Stock Promotion',
            icon: 'stock-promotion',
            color: 'red',
            glossaryLink: 'stock-promotion',
            keepTooltip: true,
            tooltip: 'The security is currently undergoing promotional activity.'
          })
        }
        if (profileBadges.verifiedProfile) {
          flags.push({
            title: 'Company Verified Profile',
            icon: 'verified-profile',
            color: 'green',
            glossaryLink: 'verified-profile',
            subtext: profileBadges.verifiedDate && moment(profileBadges.verifiedDate).format('MM/YYYY'),
            tooltip: 'The Company Profile data was verified by the issuer within the previous 6 months.'
          })
        }
        if (profileBadges.isPennyStockExempt) {
          flags.push({
            title: 'Penny Stock Exempt',
            icon: 'penny-stock-exempt',
            color: 'green',
            glossaryLink: 'penny-stock-exempt',
            tooltip: 'This security is not a Penny Stock as defined in SEC Rule 240.3a51-1.'
          })
        }
        if (profileBadges.is12g32b) {
          flags.push({
            title: '12g3-2(b) Certified',
            icon: '12g32b',
            color: 'green',
            glossaryLink: 'sec-rule-12g3-2-b',
            tooltip: 'This company has certified to OTC Markets Group that it is eligible to rely on the exemption from registration under Securities and Exchange Act Rule 12g3-2(b).'
          })
        }
        if (profileBadges.transferAgentVerified) {
          flags.push({
            title: 'Transfer Agent Verified',
            icon: 'transfer-agent',
            color: 'green',
            glossaryLink: 'transfer-agent-verified',
            tooltip: 'The company’s transfer agent has verified its outstanding shares directly to OTC Markets.'
          })
        }
        if (profileBadges.hasControlDispute) {
          flags.push({
            title: 'Control Dispute',
            icon: 'control-dispute',
            color: 'red',
            glossaryLink: 'control-dispute',
            tooltip: 'Multiple parties are engaged in a dispute over control of this company.'
          })
        }
        if (profileBadges.isShell) {
          flags.push({
            title: 'Shell',
            icon: 'shell',
            color: 'yellow',
            glossaryLink: 'shell',
            tooltip: 'A Company with no or nominal operations as declared in its SEC filings.'
          })
        }
        if (profileBadges.isShellRisk && profileBadges.isShell !== true) {
          flags.push({
            title: 'Shell Risk',
            icon: 'shell-risk',
            color: 'yellow',
            glossaryLink: 'shell-risk',
            tooltip: 'The Shell Risk designation indicates that a company displays characteristics common to Shell Companies.'
          })
        }
        if (profileBadges.isLinkedToProhibitedSP) {
          flags.push({
            title: 'Prohibited Service Provider',
            glossaryLink: 'prohibited-service-provider',
            icon: 'prohibited',
            color: 'red'
          })
        }
        if (profileBadges.unableToContact) {
          flags.push({
            title: 'Unable to Contact',
            icon: 'unable-to-contact',
            color: 'red',
            glossaryLink: 'unable-to-contact',
            tooltip: 'OTC Markets Group is unable to verify contact information for this company.'
          })
        }
        if (profileBadges.isBankrupt) {
          flags.push({
            title: 'Bankruptcy',
            icon: 'bankruptcy',
            color: 'red',
            glossaryLink: 'bankruptcy',
            tooltip: 'This company is currently in Bankruptcy or reorganization proceedings.'
          })
        }
        if (profileBadges.hasTwoIndDir && profileBadges.verifiedProfile) {
          flags.push({
            title: 'Independent Directors',
            icon: '2-independent-directors',
            color: 'green',
            glossaryLink: 'independent-director',
            tooltip: 'The Company’s board of directors includes at least two Independent Directors.'
          })
        }
        if (!this.state.info.data.isOTC && this.state?.riskScoreLatest?.data?.showRiskScore && profileBadges.isExchangeDeficient) {
          flags.push({
            title: 'Exchange Deficient',
            icon: 'exchange-deficient',
            color: 'red',
            glossaryLink: '',
            tooltip: 'Issuer has filed Item 301 disclosure noting exchange listing deficiency status.'
          })
        }
      }
    }
    const isQxQbTier = info.currentSecurity && [1, 2, 5, 6, 10].includes(info.currentSecurity.tierId)

    // todo: fetch this from the CMS
    const messages = []
    const { theme: { screen } } = this.props

    return (
      <div className={styles.companyQuotePage}>
        <HeadTitle title={company.toUpperCase()} />
        <Flexgrid layouts={layouts}>
          <CompanyQuoteHeader
            key='header'
            company={company}
            info={info}
            inside={inside}
            loaded={this.state.info.loaded && this.state.inside.loaded} />

          <div key='content'>
            <CompanyQuoteMenu
              isLoaded={this.state.info.loaded}
              company={company}
              isEquity={info.isEquity}
              isFixedIncome={info.isFixedIncome}
              items={companyQuoteTabs}
              accent={accentColor}
              activeTab={tab} />
            <Loading
              height='40em'
              loaded={this.state.info.loaded}>
              <TabComponent
                key={'content-' + company}
                user={this.props.context.user}
                company={company}
                inside={inside}
                info={info}
                contentId={contentId}
                accent={accentColor}
                contentType={contentType}
                showRiskScoring={this.state.riskScoreLatest.data.showRiskScore} />
            </Loading>
          </div>
          {!info.isFixedIncome && <div key='profile' className={styles.profile}>
            {(tierImageUrl || awardImage || basicInfo) && <div className={styles.tierSection}>
              {(tierImageUrl || awardImage) && <Tooltip
                text={tierDesc}
                width='260px'
                placement='bottom'>
                <img src={awardImage || tierImageUrl} className={awardImage && styles.award} />
              </Tooltip>}
              {basicInfo && <div className={styles.infoText}>{basicInfo}</div>}
              {info.indexStatuses && info.indexStatuses.length > 0 && !isQxQbTier && <Hide sm><div key='tier' className={styles.tierText}>
                {tierName}
              </div></Hide>}
            </div>}
            {<Outline
              mode='section1'
              spacing='small'
              margin='none'
              className={styles.informationOutline}
              accent={accentColor}>
              {info.indexStatuses && !isQxQbTier && tierName && (
                info.indexStatuses.length > 0 ? (
                  <Hide md lg>
                    <div className={styles.infoText}>{tierName}</div>
                  </Hide>
                ) : (
                  <div className={styles.infoText}>{tierName}</div>
                )
              )}
            </Outline>}
          </div>}

          <div key='indexes' className={styles.indexBoxContainer}>
            {tierName &&
            <Flex w={1} column>
              {info.indexStatuses && info.indexStatuses.length > 0 && <Flex wrap row justify={['center', 'space-between', 'center']}>
                {info.indexStatuses.map(index =>
                  <Box w={[1 / 2, '49.5%', 1]} key={index.indexSymbol} accent={accentColor}><Hide sm><Link
                    to={`/index/${index.indexSymbol}`}
                    key={`sidebar-index-${index.indexSymbol}`}
                    className={classNames(styles.indexBox, styles[accentColor])}>
                    {index.indexName}
                    <span className={styles.indexLink}>Index</span>
                  </Link></Hide></Box>)}
              </Flex>}
            </Flex>
            }
          </div>

          {tierName && <Outline
            mode='section1'
            spacing='small'
            margin='none'
            className={styles.informationOutline}
            accent={accentColor}>
            <Flex w={1} wrap>
              {info.indexStatuses && info.indexStatuses.length > 0 && <Hide sm>
                {info.indexStatuses.map(index => <Link
                  key={`sidebar-index-${index.indexSymbol}`}
                  className={styles.infoText}
                  to={`/index/${index.indexSymbol}`}>
                  {index.indexName}
                </Link>)}
              </Hide>}
              {info.indexStatuses && !isQxQbTier && (
                info.indexStatuses.length > 0 ? (
                  <Hide md lg>
                    <div className={styles.infoText}>{tierName}</div>
                  </Hide>
                ) : (
                  <div className={styles.infoText}>{tierName}</div>
                )
              )}
            </Flex>
          </Outline>}

          {messages && messages.length > 0 && <div key='messages' className={styles.messages}>
            {messages.map((message, index) => <p
              key={`sidebar-message-${index}`}>
              {message}
            </p>)}
          </div>}

          <div key='flags' className={styles.flags}>
            <Flex wrap className={styles.flagsGrid}>
              <Box className={styles.flagsLeft}>
                <div className={styles.flagsContent}>
                  {flags.map((flag, index) => <div
                    key={`sidebar-flag-${index}`}>
                    <Tooltip
                      text={flag.tooltip}
                      title={flag.tooltipTitle}
                      keepTooltip={flag.keepTooltip}
                      width='260px'
                      placement='bottom'>
                      <div className={classNames({
                        [styles.greenFlag]: flag.color === 'green',
                        [styles.yellowFlag]: flag.color === 'yellow',
                        [styles.redFlag]: flag.color === 'red'
                      })}>
                        <img className={styles.icon} src={`/badges/${flag.icon}.png`} />
                        <div className={styles.flagsText}>
                          {flag.title}
                          {flag.subtext && <span className={styles.subtext}> {flag.subtext}</span>}
                        </div>
                      </div>
                    </Tooltip>
                  </div>)}
                </div>
              </Box>
            </Flex>
          </div>

          {((info.otherSecurities && info.otherSecurities.length) || screen.type === 'md') && <div key='related-securities' className={styles.securities}>
            <Outline mode='heading' accent={accentColor}>Securities</Outline>
            {info.otherSecurities && info.otherSecurities.length > 0 && <div>
              <b>Other {info.name} Securities</b>
              <ul>
                {info.otherSecurities.map(security => (
                  <li key={`security-symbol-${security.symbol}`}>
                    <SymbolLink data={security} />
                  </li>
                ))}
              </ul>
            </div>
            }

            {info.otherSecurities && info.otherSecurities.length < 1 && <p>{info.name} does not have any other securities</p>}
          </div>}
          {this.state.riskScoreLatest.data.showRiskScore && this.state.riskScoreLatest.loaded && <div key='total-score' className={classNames(styles.totalContainer, styles[accentColor || 'default'])}>
            <div className={styles.total}>{this.state.riskScoreLatest.data.totalRiskScore}</div>
            <div>Risk Score</div>
          </div>}
          {this.state.riskScoreLatest.data.showRiskScore && this.state.riskScoreLatest.loaded && <RiskScore isEquity={this.state.info.data.isEquity} key='risk-score' accent={accentColor} stats={this.state.riskScoreLatest.data} company={company} tab={this.props.match.params.tab} />}
          <div key='empty' />
        </Flexgrid>
      </div>
    )
  }
}

CompanyQuotePage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      company: PropTypes.string.isRequired,
      tab: PropTypes.string,
      page: PropTypes.string,
      contentType: PropTypes.string,
      contentId: PropTypes.string
    }).isRequired
  }).isRequired,
  theme: PropTypes.shape({
    screen: PropTypes.shape({
      type: PropTypes.string.isRequired,
      index: PropTypes.number.isRequired
    }).isRequired
  }).isRequired,
  context: PropTypes.object,
  info: PropTypes.object
}

CompanyQuotePage.defaultProps = {
  theme: {
    screen: {
      type: 'lg',
      index: 0
    }
  }
}

export default withRouter(withTheme(withContext(CompanyQuotePage)))
