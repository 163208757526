export default {
  'venue-changes': [
    {
      name: 'changeDate',
      header: 'Date',
      type: 'date',
      sortingEnabled: true
    },
    {
      name: 'oldSymbol',
      header: 'Old Symbol',
      headerShort: 'OldSym',
      sortingEnabled: true
    },
    {
      name: 'newSymbol',
      header: 'New Symbol',
      headerShort: 'NewSym',
      sortingEnabled: true,
      type: 'symbol'
    },
    {
      name: 'oldVenueName',
      header: 'Old Venue',
      headerShort: 'OldVen',
      sortingEnabled: true
    },
    {
      name: 'newVenueName',
      header: 'New Venue',
      headerShort: 'NewVen',
      sortingEnabled: true
    },
    {
      name: 'companyName',
      header: 'Company Name',
      headerShort: 'Name',
      sortingEnabled: true
    },
    {
      name: 'className',
      header: 'Security Type',
      headerShort: 'SecTyp',
      sortingEnabled: true
    }
  ],
  'symbol-changes': [
    {
      name: 'changeDate',
      header: 'Date',
      type: 'date',
      sortingEnabled: true
    }, {
      name: 'oldSymbol',
      header: 'Old Symbol',
      headerShort: 'OldSym',
      sortingEnabled: true
    }, {
      name: 'newSymbol',
      header: 'New Symbol',
      headerShort: 'NewSym',
      sortingEnabled: true,
      type: 'symbol'
    }, {
      name: 'companyName',
      header: 'Company Name',
      headerShort: 'Name',
      sortingEnabled: true
    }, {
      name: 'className',
      header: 'Security Type',
      headerShort: 'SecTyp',
      sortingEnabled: true
    }, {
      name: 'tierName',
      header: 'Market',
      headerShort: 'Mkt',
      sortingEnabled: true
    }, {
      name: 'typeName',
      header: 'Split Type',
      headerShort: 'SptTyp',
      sortingEnabled: true,
      sortingKey: 'typeId'
    }, {
      name: 'splitFactor',
      header: 'Split Factor',
      headerShort: 'SptRat',
      sortingEnabled: false
    }
  ],
  'name-changes': [
    {
      name: 'changeDate',
      header: 'Date',
      type: 'date',
      sortingEnabled: true
    }, {
      name: 'oldName',
      header: 'Old Name',
      headerShort: 'OldNam',
      sortingEnabled: false
    }, {
      name: 'newName',
      header: 'New Name',
      headerShort: 'NewNam',
      sortingEnabled: true
    }, {
      name: 'symbol',
      header: 'Symbol',
      headerShort: 'Sym',
      sortingEnabled: true,
      type: 'symbol'
    }, {
      name: 'className',
      header: 'Security Type',
      headerShort: 'SecTyp',
      sortingEnabled: true
    }, {
      name: 'tierName',
      header: 'Market',
      headerShort: 'Mkt',
      sortingEnabled: true
    }
  ],
  'symbol-name-changes': [
    {
      name: 'changeDate',
      header: 'Date',
      type: 'date',
      sortingEnabled: true
    }, {
      name: 'oldSymbol',
      header: 'Old Symbol',
      headerShort: 'OldSym',
      sortingEnabled: true
    }, {
      name: 'oldName',
      header: 'Old Name',
      headerShort: 'OldNam',
      sortingEnabled: true
    }, {
      name: 'newSymbol',
      header: 'New Symbol',
      headerShort: 'NewSym',
      sortingEnabled: true,
      type: 'symbol'
    }, {
      name: 'newName',
      header: 'New Name',
      headerShort: 'NewNam',
      sortingEnabled: true
    }, {
      name: 'className',
      header: 'Security Type',
      headerShort: 'SecTyp',
      sortingEnabled: true
    }, {
      name: 'tierName',
      header: 'Market',
      headerShort: 'Mkt',
      sortingEnabled: true
    }, {
      name: 'typeName',
      header: 'Split Type',
      headerShort: 'SptTyp',
      sortingEnabled: true,
      sortingKey: 'typeId'
    }, {
      name: 'splitFactor',
      header: 'Split Factor',
      headerShort: 'SptRat',
      sortingEnabled: false
    }
  ],
  splits: [
    {
      name: 'changeDate',
      header: 'Date',
      type: 'date',
      sortingEnabled: true
    }, {
      name: 'splitType',
      header: 'Split Type',
      headerShort: 'SptTyp',
      sortingEnabled: false
    }, {
      name: 'oldSymbol',
      header: 'Old Symbol',
      headerShort: 'OldSym',
      sortingEnabled: true
    }, {
      name: 'newSymbol',
      header: 'New Symbol',
      headerShort: 'NewSym',
      sortingEnabled: true,
      type: 'symbol'
    }, {
      name: 'companyName',
      header: 'Company Name',
      headerShort: 'Name',
      sortingEnabled: true
    }, {
      name: 'tierName',
      header: 'Market',
      headerShort: 'Mkt',
      sortingEnabled: true
    }, {
      name: 'effectiveDate',
      header: 'Effective Date',
      headerShort: 'EffDate',
      type: 'date',
      sortingEnabled: false
    }, {
      name: 'recordDate',
      header: 'Record Date',
      headerShort: 'RecDate',
      type: 'date',
      sortingEnabled: false
    }, {
      name: 'payDate',
      header: 'Payment Date',
      headerShort: 'PayDate',
      type: 'date',
      sortingEnabled: false
    }, {
      name: 'splitFactor',
      header: 'Split Factor',
      headerShort: 'SptRat',
      sortingEnabled: false
    }
  ],
  deletions: [
    {
      name: 'changeDate',
      header: 'Date',
      type: 'date',
      sortingEnabled: true
    }, {
      name: 'symbol',
      header: 'Symbol',
      headerShort: 'Sym',
      sortingEnabled: true
    }, {
      name: 'companyName',
      header: 'Company Name',
      headerShort: 'Name',
      sortingEnabled: true
    }, {
      name: 'className',
      header: 'Security Type',
      headerShort: 'SecTyp',
      sortingEnabled: true
    }, {
      name: 'tierName',
      header: 'Market',
      headerShort: 'Mkt',
      sortingEnabled: true
    }, {
      name: 'deletionStatus',
      header: 'Status',
      sortingKey: 'typeId',
      sortingEnabled: true
    }, {
      name: 'comments',
      header: 'Comments',
      headerShort: 'Cmnts'
    }
  ],
  halts: [
    {
      name: 'changeDate',
      header: 'Effective Date',
      headerShort: 'EffDate',
      type: 'date',
      sortingEnabled: true
    }, {
      name: 'symbol',
      header: 'Symbol',
      headerShort: 'Sym',
      sortingEnabled: true,
      type: 'symbol'
    }, {
      name: 'companyName',
      header: 'Company Name',
      headerShort: 'Name',
      sortingEnabled: true
    }, {
      name: 'className',
      header: 'Security Type',
      headerShort: 'SecTyp',
      sortingEnabled: true
    }, {
      name: 'tierName',
      header: 'Market',
      headerShort: 'Mkt',
      sortingEnabled: true
    }, {
      name: 'name',
      header: 'Status',
      sortingEnabled: true,
      sortingKey: 'statusId'
    }
  ],
  'proprietary-quote-eligible': [
    {
      name: 'changeDate',
      header: 'Effective Date',
      headerShort: 'EffDate',
      type: 'date',
      sortingEnabled: true
    }, {
      name: 'symbol',
      header: 'Symbol',
      headerShort: 'Sym',
      sortingEnabled: true,
      type: 'symbol'
    }, {
      name: 'companyName',
      header: 'Company Name',
      headerShort: 'Name',
      sortingEnabled: true
    }, {
      name: 'className',
      header: 'Security Type',
      headerShort: 'SecTyp',
      sortingEnabled: true
    }, {
      name: 'tierName',
      header: 'Market',
      headerShort: 'Mkt',
      sortingEnabled: true
    }
  ],
  'ex-dividends': [
    {
      name: 'exerciseDate',
      header: 'Ex-Date',
      headerShort: 'ExDate',
      type: 'date',
      sortingEnabled: true
    }, {
      name: 'symbol',
      header: 'Symbol',
      headerShort: 'Sym',
      sortingEnabled: true,
      type: 'symbol'
    }, {
      name: 'companyName',
      header: 'Company Name',
      headerShort: 'Name',
      sortingEnabled: true
    }, {
      name: 'securityType',
      sortingKey: 'className',
      header: 'Security Type',
      headerShort: 'SecTyp',
      sortingEnabled: true
    }, {
      name: 'tierName',
      header: 'Market',
      headerShort: 'Mkt',
      sortingEnabled: true
    }, {
      name: 'typeName',
      header: 'Type',
      sortingEnabled: false
    }, {
      name: 'cashAmount',
      header: 'Cash Amt.',
      headerShort: '$Amt',
      type: 'price',
      sortingEnabled: true
    }, {
      name: 'stockAmount',
      header: 'Stock Amt.',
      headerShort: 'StkAmt',
      type: 'price',
      sortingEnabled: true
    }
  ],
  'sch-dividends': [
    {
      name: 'exerciseDate',
      header: 'Sch. Date',
      headerShort: 'SchDate',
      type: 'date',
      sortingEnabled: true
    }, {
      name: 'symbol',
      header: 'Symbol',
      headerShort: 'Sym',
      sortingEnabled: true,
      type: 'symbol'
    }, {
      name: 'companyName',
      header: 'Company Name',
      headerShort: 'Name',
      sortingEnabled: true
    }, {
      name: 'securityType',
      sortingKey: 'className',
      header: 'Security Type',
      headerShort: 'SecTyp',
      sortingEnabled: true
    }, {
      name: 'tierName',
      header: 'Market',
      headerShort: 'Mkt',
      sortingEnabled: true
    }, {
      name: 'typeName',
      header: 'Type',
      sortingEnabled: false
    }, {
      name: 'cashAmount',
      header: 'Cash Amt.',
      headerShort: '$Amt',
      type: 'price',
      sortingEnabled: true
    }, {
      name: 'stockAmount',
      header: 'Stock Amt.',
      headerShort: 'StkAmt',
      type: 'price',
      sortingEnabled: true
    }
  ],
  'tier-changes': [
    {
      name: 'changeDate',
      header: 'Date',
      type: 'date',
      sortingEnabled: true
    }, {
      name: 'symbol',
      header: 'Symbol',
      headerShort: 'Sym',
      sortingEnabled: true,
      type: 'symbol'
    }, {
      name: 'companyName',
      header: 'Company Name',
      headerShort: 'Name',
      sortingEnabled: true
    }, {
      name: 'oldTierName',
      header: 'Old Market',
      headerShort: 'OldMkt',
      sortingEnabled: true,
      sortingKey: 'oldTier'
    }, {
      name: 'newTierName',
      header: 'New Market',
      headerShort: 'NewMkt',
      sortingEnabled: true,
      sortingKey: 'newTier'
    }, {
      name: 'className',
      header: 'Security Type',
      headerShort: 'SecTyp',
      sortingEnabled: true
    }
  ],
  'ce-changes': [
    {
      name: 'changeDate',
      header: 'Date',
      type: 'date',
      sortingEnabled: true
    }, {
      name: 'symbol',
      header: 'Symbol',
      headerShort: 'Sym',
      sortingEnabled: true,
      type: 'symbol'
    }, {
      name: 'companyName',
      header: 'Company Name',
      headerShort: 'Name',
      sortingEnabled: true
    }, {
      name: 'tierName',
      header: 'Tier',
      sortingEnabled: true
    }, {
      name: 'caveatEmptorStatus',
      header: 'Caveat Emptor Status',
      headerShort: 'CEStat',
      sortingEnabled: false
    }, {
      name: 'className',
      header: 'Security Type',
      headerShort: 'SecTyp',
      sortingEnabled: true
    }
  ]
}
