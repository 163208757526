import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'grid-styled'
import ExternalLink from '../../../components/ExternalLink'
import styles from './styles.module.scss'

const CompanyPqeReasonInfo = (props) => {
  let array = []
  if (props.value) {
    if (Array.isArray(props.value)) {
      array = [...props.value]
    } else {
      array.push(props.value)
    }
  }
  return <Flex w={[1, 1 / 2]} className={styles.item} wrap>
    {array && array.length > 0 && array.map((item, index) => <div key={index} className={styles.field}>
      {props.link && <ExternalLink className={styles.link} to={props.value}>{props.linkTitle}</ExternalLink>}
      {!props.link && <span>{item}</span>}
    </div>)}
  </Flex>
}

CompanyPqeReasonInfo.propTypes = {
  value: PropTypes.object,
  linkTitle: PropTypes.string,
  link: PropTypes.any
}

export default CompanyPqeReasonInfo
