import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import equal from 'fast-deep-equal'
import { withRouter } from '../../components/WithRouter'
import ContentPage from './ContentPage'
import { getPage, getPageBySlug } from '../../api/content/pages'
import NotFound from '../NotFound'
import Loading from '../../components/Loading'
import { logPageview } from '../../analytics'

class ContentPageContainer extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      loaded: false,
      page: props.page || null,
      notFound: false,
      error: null
    }
  }

  componentDidMount () {
    if (this.state.page) { return }
    this.loadPage(this.props.match, this.props.location)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    // Load new page if changed
    if (!equal(this.props, nextProps)) {
      this.setState({ page: null, notFound: false, loaded: false, error: null })
      this.loadPage(nextProps.match, nextProps.location)
    }
  }

  loadPage = (match, location) => {
    // get by page id
    const pageId = match.params.pageId
    let route = getPage
    let arg = pageId

    // otherwise get by path
    if (pageId === null || pageId === undefined) {
      route = getPageBySlug
      arg = location.pathname.slice(1)
      if (arg[arg.length - 1] === '/') {
        arg = arg.slice(0, arg.length - 1)
      }
    }

    // get query params
    const query = queryString.parse(location.search)

    // get preview query param
    const preview = 'preview' in query

    if (arg === 'directories/premium-provider-directory') {
      arg = 'corporate-services/premium-provider-directory';
    }

    route(arg, preview)
      .then(page => {
        if (page === '' || (page && page.message && page.message === "Object doesn't exist")) {
          this.setState(state => ({
            notFound: true
          }))
        } else {
          this.setState(state => ({
            error: null,
            notFound: false,
            loaded: true,
            page
          }))
          logPageview(page && page.title ? page.title : '')
        }
      })
      .catch(err => {
        let error = 'Error loading page'
        // this is axios exception https://github.com/axios/axios#handling-errors
        if (err.response) {
          if (err.response.status === 403) {
            error = 'Access Denied'
          } else if (err.response.status === 404) {
            error = 'Page Not Found'
          } else {
            console.error(err)
          }
        } else {
          console.error(err)
        }
        this.setState(state => ({
          error,
          notFound: true
        }))
      })
  }

  render () {
    const { error, notFound, ...props } = this.state

    if (notFound) {
      return <NotFound />
    } else if (!this.state.loaded) {
      return <Loading loaded={false} height='40em' />
    }

    return (
      <div>
        <ContentPage {...props} />
      </div>
    )
  }
}

ContentPageContainer.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      pageId: PropTypes.string
    }).isRequired
  }).isRequired,
  location: PropTypes.object,
  page: PropTypes.object
}

export default withRouter(ContentPageContainer)
