import React from 'react'
import PropTypes from 'prop-types'
import HeadTitle from '../../components/HeadTitle'
import RichContent from '../../components/RichContent'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import Flexgrid from '../../components/Flexgrid'
import PageTitle from '../../components/PageTitle'
import BackLink from '../../components/BackLink'
import ContentMenu from '../../components/ContentMenu'
import DirSearch from '../../components/DirSearch'
import { Flex, Box } from 'grid-styled'
import { withRouter } from '../../components/WithRouter'
import classNames from 'classnames'
import styles from './ContentPage.module.scss'

const getText = node => {
  let text = ''
  if (node.ranges) {
    node.ranges.forEach(range => {
      text += range.text
    })
  }
  if (node.nodes) {
    node.nodes.forEach(child => {
      text += getText(child)
    })
  }
  return text
}

class ContentPage extends React.PureComponent {
  state = {
    searchQuery: null
  }

  componentDidMount () {
    // If anchor is supplied in hash, scroll into view
    const hash = window.location.hash
    if (hash && hash.length > 1) {
      const el = document.getElementById(hash.substr(1))
      if (el) {
        setTimeout(() => { el.scrollIntoView(true) }, 10)
      }
    }
  }

  onSearch = searchQuery => {
    this.setState({ searchQuery })
  }

  isGlossary = () => {
    const path = this.props.location.pathname
    return path.toLowerCase().substr(1) === 'glossary'
  }

  isFaq = () => {
    const path = this.props.location.pathname
    return path.toLowerCase().indexOf('faqs') === path.length - 4
  }

  getSections = () => {
    const searchQuery = (this.state.searchQuery || '').trim().toLowerCase()
    let sections = (this.props.page && this.props.page.sections) || []
    if (this.isFaq()) {
      if (sections.length) {
        sections.forEach(section => {
          section.content && section.content.document && section.content.document.nodes.forEach(node => {
            if (node.type === 'accordion_table' && node.nodes) {
              for (let i = 0; i < node.nodes.length; i = i + 2) {
                const question = node.nodes[i]
                const answer = node.nodes[i + 1]
                const questionText = getText(question).toLowerCase()
                const answerText = getText(answer).toLowerCase()

                if (searchQuery !== '' &&
                  questionText.indexOf(searchQuery) === -1 && answerText.indexOf(searchQuery) === -1) {
                  question.hide = answer.hide = true
                } else {
                  question.hide = answer.hide = false
                }
              }
            }
          })
        })
      }
    } else if (this.isGlossary()) {
      const filteredSections = []
      if (sections.length) {
        sections.forEach(section => {
          const sidebarText = getText(section.sidebar.document).toLowerCase().trim()
          section.anchor = sidebarText.replace(/[\W_]+/g, ' ').trim().replace(/ /g, '-')
          const mainText = getText(section.content.document).toLowerCase().trim()
          if (searchQuery === '' || sidebarText.indexOf(searchQuery) > -1 || mainText.indexOf(searchQuery) > -1) {
            section.border = 'gray'
            filteredSections.push(section)
          }
        })
      }
      // add message if no matches found
      if (filteredSections.length === 0) {
        filteredSections.push({
          layout: 'sidebar',
          sidebarLocation: 'before',
          content: {
            kind: 'state',
            document: {
              kind: 'document',
              nodes: [
                {
                  kind: 'block',
                  isVoid: false,
                  type: 'line',
                  nodes: [
                    {
                      kind: 'text',
                      ranges: [
                        {
                          kind: 'range',
                          text: 'No matches found',
                          marks: []
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          }
        })
      }
      filteredSections[0].border = 'Default Border'
      sections = filteredSections
    }
    return sections
  }

  render () {
    const props = this.props
    const sections = this.getSections()
    if (sections.length) {
      // Build breadcrumbs
      const breadcrumbs = [...props.page.parents.reduce((a, b) => {
        const prev = a[a.length - 1]
        const cur = Object.assign({}, b)
        cur.slug = prev ? prev.slug + '/' + cur.slug : '/' + cur.slug

        if (cur.slug === '/corporate-services') {
          cur.slug = '/directories'
          cur.title = 'Directories'
        }

        return a.concat(cur)
      }, []), props.page.tabTitle || props.page.title]

      // Flexgrid layout for content sections
      const layouts = (borderStyle, sidebarLocation) => ({
        sidebar: {
          default: [
            {
              type: 'box',
              id: 'sectionTitle'
            },
            {
              type: 'box',
              className: styles.mainContent,
              id: sidebarLocation === 'before' ? 'sidebarContent' : 'mainContent'
            },
            {
              type: 'box',
              id: sidebarLocation === 'before' ? 'mainContent' : 'sidebarContent'
            }
          ],
          lg: [
            {
              type: 'flex',
              subtree: [
                {
                  type: 'side',
                  className: styles.sidebarContent,
                  subtree: ['sectionTitle', 'sidebarContent']
                },
                {
                  type: 'main',
                  className: classNames(styles.mainContent, styles['border-' + (borderStyle || props.page.accentColor)]),
                  id: 'mainContent'
                }
              ]
            }
          ]
        },
        fullwidth: {
          default: [
            {
              type: 'flex',
              className: classNames(styles.mainContent, styles.fullWidthContent, styles['border-' + (borderStyle || props.page.accentColor)]),
              column: true,
              id: 'mainContent'
            }
          ]
        }
      })

      const firstSection = sections.length && sections[0]

      // Show page search
      const showSearch = this.isGlossary() || this.isFaq()

      return (
        <div className={styles.ContentPage}>
          <Breadcrumbs items={breadcrumbs} />
          <HeadTitle title={props.page.title} />
          <PageTitle accent={props.page.accentColor || 'green'}>{props.page.title}</PageTitle>
          <Flex className={classNames(styles.topBar, firstSection && (!firstSection.layout || firstSection.layout === 'sidebar') && styles.hasSidebar)}>
            <Flex className={styles.backButtonHolder}>
              {!showSearch && props.page.parents && props.page.parents.length > 1 && <BackLink to={'/' + props.page.parents.map(p => p.slug).join('/')} />}
              {showSearch && <div className={styles.searchHolder}>
                <DirSearch onSearch={this.onSearch} value={this.state.searchQuery} />
              </div>}
            </Flex>
            {parseInt(props.page.hideTabs) !== 1 && <Box className={styles.menuHolder}>
              <ContentMenu tabs={props.page.tabs} accent={props.page.accentColor} />
            </Box>}
          </Flex>
          <div>
            { sections.map((section, i) =>
              <Flexgrid wrap layouts={layouts(section.border, section.sidebarLocation)[section.layout || 'sidebar']} className={classNames(styles.section, styles['border-' + (section.border || props.page.accentColor)])} key={i} id={section.anchor}>
                <div key='sectionTitle' className={classNames(styles.sectionTitle, styles['color-' + props.page.accentColor], styles['border-' + (section.border || props.page.accentColor)])}>
                  {section.title && <div>{section.title}</div>}
                </div>
                <RichContent key='sidebarContent' content={section.sidebar} accentColor={props.page.accentColor} />
                <RichContent key='mainContent' content={section.content} location={this.props.location} accentColor={props.page.accentColor} searchQuery={this.state.searchQuery} />
              </Flexgrid>
            )}
          </div>
        </div>
      )
    }
    return null
  }
}

ContentPage.propTypes = {
  page: PropTypes.object,
  location: PropTypes.object
}

export default withRouter(ContentPage)
