import React from 'react'
import PropTypes from 'prop-types'
import styles from './CompanyQuoteQuote.module.scss'
import moment from 'moment-timezone'
import { getTradeData } from '../../api/otc/stock/trades'
import { getShortSaleData } from '../../api/otc/stock/short-sales'
import { getLevel2Montage } from '../../api/otc/stock/stock'
import { logPageview } from '../../analytics'

import { template, fetch, expand, sort } from '../../api/helper'

import Table from '../Table'
import Outline from '../Outline'
import Loading from '../Loading'
import Accordion from '../Accordion'
import CompanyPqeReasonInfo from '../AccordionItems/CompanyPqeReasonInfo'
import Hide from '../Hide'
import More from '../More'
import TableFooter from '../TableFooter'
import DisplayResults from '../DisplayResults'
import Chart from '../Chart'
import QuoteInside from '../QuoteInside'
import Level2 from '../Level2'
import TickerPrice from '../TickerPrice'

const hostname = window.location.origin

function changeColor (tickValue) {
  if (tickValue) {
    if (tickValue === 'Up') return 'limegreen'
    else if (tickValue === 'Down') return 'red'
  }
  return null
}

const tradeDataColumns = [{
  name: 'tradeDate',
  header: 'Date',
  type: 'staticDate'
}, {
  name: 'tradeTime',
  header: 'Timestamp'
}, {
  name: 'lastPrice',
  header: 'Price',
  type: 'price',
  component: TickerPrice
}, {
  name: 'change',
  header: '$ Change',
  type: 'float',
  color: item => changeColor(item.tradeDirection),
  hideOnMobile: true
}, {
  name: 'lastVolume',
  header: 'Volume',
  type: 'int'
}]

const shortSalesColumns = [{
  name: 'positionDate',
  header: 'Date',
  type: 'date',
  sortingEnabled: true
}, {
  name: 'shortInterest',
  header: 'Short Interest',
  type: 'int',
  sortingEnabled: true,
  sortingKey: 'volume'
}, {
  name: 'pctChgVolume',
  header: '% Change',
  type: 'float',
  sortingEnabled: true
}, {
  name: 'avgDailyVolume',
  header: 'AVG. Daily Share Vol',
  type: 'int',
  sortingEnabled: true
}, {
  name: 'daysToCover',
  header: 'Days to Cover',
  hideOnMobile: true,
  sortingEnabled: true
}, {
  name: 'isSplit',
  header: 'Split',
  type: 'bool',
  sortingEnabled: true,
  sortingKey: 'split'
}, {
  name: 'isNewIssue',
  header: 'New Issue',
  type: 'bool',
  sortingEnabled: true,
  sortingKey: 'newIssue'
}]

class CompanyQuoteQuote extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      tradeData: template('records'),
      shortSales: template('records', 'symbol', 'asc'),
      montage: {
        loaded: false,
        data: {
          montageBuyList: [],
          montageSellList: []
        },
        error: null
      }
    }
  }

  getData (func, objectName, formatting) {
    const { company } = this.props

    func(company)
      .then(data => {
        if (formatting) {
          data = formatting(data)
        }

        this.setState(state => ({
          [objectName]: {
            loaded: true,
            error: null,
            data
          }
        }))
      })
      .catch((err) => {
        console.error(err)
        this.setState(state => ({
          [objectName]: {
            loaded: false,
            error: 'Something went wrong, please try again.',
            data: state[objectName].data
          }
        }))
      })
  }

  componentDidMount () {
    const { info } = this.props

    // don't load any data
    // if (!info.hasQuotes) return

    // get level 2 montage
    this.getData(getLevel2Montage, 'montage', data => {
      return data || {
        montageBuyList: [],
        montageSellList: []
      }
    })

    // get trade data
    this.getTrade()

    // get short sales
    this.getShortSales()

    const title = `OTC Markets | ${this.props.company} | Quote`
    logPageview(title)

    // If anchor is supplied in hash, scroll into view
    const hash = window.location.hash
    if (hash && hash.length > 1) {
      const el = document.getElementById(hash.substr(1))
      if (el) {
        setTimeout(() => { el.scrollIntoView(true) }, 100)
      }
    }
  }

  getTrade = () => {
    fetch(this, getTradeData, 'tradeData', {
      symbol: this.props.company,
      page: 1,
      pageSize: 10
    })
  }

  getShortSales = () => {
    fetch(this, getShortSaleData, 'shortSales', {
      symbol: this.props.company,
      page: 1,
      pageSize: 10
    })
  }

  render () {
    const { accent, company, inside, info } = this.props
    const showPqe = info && (info.isOTC || info.isGrey)
    const peqReasons = info && info.currentSecurity && info.currentSecurity.quoteEligReasons ? info.currentSecurity.quoteEligReasons : []

    return <div className={styles.CompanyQuoteQuote}>
      <QuoteInside quote={inside} profile={info} />
      {info.isOTC && <Level2 data={this.state.montage.data}
        loaded={this.state.montage.loaded}
        symbol={this.props.company}
        accent={accent} />}

      <Hide sm>
        <Outline mode='invisible' spacing='larger'>
          <Chart symbol={company} />
        </Outline>
      </Hide>

      {showPqe && <div id='pqe-data' className={styles.pqeTable}>
      <Outline mode='heading' accent={accent}>Eligibility</Outline>
      <Accordion
          compact
          list={[{
            title: 'Quote Eligibility',
            hasContent: false,
            data: {
              external: true,
              value: (info.currentSecurity &&
                  ((info.currentSecurity.isQuoteElig &&  `${hostname}/otc-content/glossary#pqe-proprietary-quote-eligible`) || (!info.currentSecurity.isQuoteElig && `${hostname}/otc-content/glossary#unsolicited-quote`))) ||
                  'Not Available',
              link: info.currentSecurity && info.currentSecurity.isQuoteElig !== null,
              linkTitle: (info.currentSecurity &&
                  ((info.currentSecurity.isQuoteElig && 'Proprietary Quote Eligible (PQE)') || (!info.currentSecurity.isQuoteElig && 'Unsolicited Quotes Only (UNS)'))) ||
                  'Not Available'
            }
          }, {
            title: 'PQE Reason',
            hasContent: false,
            visible: peqReasons.length > 0 && info.currentSecurity && info.currentSecurity.isQuoteElig,
            data: {
              value: peqReasons
            }
          }, {
            title: 'Grace Period',
            hasContent: false,
            tooltip: "This security has entered a 15 calendar day Grace Period before it is downgraded to the Expert Market because OTC Markets Group is unable to confirm that the issuer's disclosure is current and publicly available under Rule 15c2-11.",
            tooltipTitle: 'Warning! Grace Period',
            visible: info.currentSecurity && !!info.currentSecurity.hasGracePeriod && (info.tierGroup === 'QX' || info.tierGroup === 'DQ' || info.tierGroup === 'PS'),
            data: {
              value: `Yes, Last Day of Grace Period: ${moment(info.currentSecurity.gracePeriodEndDate).format('MM/DD/YYYY')}`
            }
          }, {
            title: 'Overnight Trading Eligible',
            hasContent: false,
            data: {
              value: (info.currentSecurity &&
             ((info.currentSecurity.isOvernight && 'Yes') || (!info.currentSecurity.isOvernight && 'No'))) ||
             'Not Available'
            }
          }]}
          titleComponent={CompanyPqeReasonInfo} />
      </div> }

      {!info.isListed && <div id='trade-data' className={styles.tradeDataTable}>
        <Outline mode='heading' accent={accent}>Trade Data</Outline>
        <Loading
          type='table'
          loaded={this.state.tradeData.loaded}
          error={this.state.tradeData.error}
          reloading={this.state.tradeData.reloading}
          data={this.state.tradeData.data}>
          <Table
            columns={tradeDataColumns}
            loaded={this.state.tradeData.loaded}
            data={this.state.tradeData.data}
            showHeader
          />
          <TableFooter>
            <More onClick={expand(this, getTradeData, 'tradeData')}
              disabled={this.state.tradeData.expandEmpty} />
            {this.state.tradeData.data.records && this.state.tradeData.data.totalRecords &&
              <DisplayResults show={this.state.tradeData.data.records.length} total={this.state.tradeData.data.totalRecords} text='results' />
            }
          </TableFooter>
          <div className={styles.footerNote}>Irregular/odd lot trades, which are not considered for the Open, High, Low or Closing prices, are not shown in trade data table.</div>
        </Loading>
      </div>}

      {info.isEquity && <div className={styles.shortSalesTable}>
        <Outline mode='heading' accent={accent}>Short Interest</Outline>
        <Loading type='table'
          loaded={this.state.shortSales.loaded}
          error={this.state.shortSales.error}
          reloading={this.state.shortSales.reloading}
          data={this.state.shortSales.data}>
          <Table
            columns={shortSalesColumns}
            data={this.state.shortSales.data.records}
            sortColumn={this.state.shortSales.sortOn}
            sortDirection={this.state.shortSales.sortDir}
            onSorting={sort(this, getShortSaleData, 'shortSales')}
            showHeader
          />
          <TableFooter>
            <More onClick={expand(this, getShortSaleData, 'shortSales')}
              disabled={this.state.shortSales.expandEmpty} />
            {this.state.shortSales.data.records && this.state.shortSales.data.totalRecords &&
              <DisplayResults show={this.state.shortSales.data.records.length} total={this.state.shortSales.data.totalRecords} text='results' />
            }
          </TableFooter>
        </Loading>
      </div>}

      {info.isOTC && <div className={styles.footerNote}>
        <b>Level 2 Quote Montage: </b>
        MMID - OTC Link Inside Quote | MMID - OTC Link Quote | cMMID - Closed Quote |
        U - Unpriced Quote | MMIDu - Unsolicited Quote. All trade/quote prices in USD.
      </div>}
    </div>
  }
}

CompanyQuoteQuote.propTypes = {
  company: PropTypes.string.isRequired,
  accent: PropTypes.string,
  info: PropTypes.object,
  inside: PropTypes.object
}

export default CompanyQuoteQuote
